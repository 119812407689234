import React from 'react'
import styled from 'styled-components/macro'

import Modal from '../index'
interface Props {
  isOpen: boolean
  onDismiss: () => void
}
export interface DataModalLoading {
  type: string
  amount?: string | number
}
const ModalSuccess = (props: Props) => {
  const { isOpen, onDismiss } = props
  return (
    <Modal isOpen={isOpen} onDismiss={onDismiss}>
      <ModalContent>
        <ModalHeader>Success</ModalHeader>
      </ModalContent>
    </Modal>
  )
}
const ModalContent = styled.div`
  padding: 0;
  width: 100%;
  background: ${({ theme }) => theme.green2};
`
const ModalHeader = styled.h3`
  font-family: 'Montserrat', sans-serif;
  color: ${({ theme }) => theme.black};
  text-align: center;
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 49px;
`
export default ModalSuccess
