import Mailchimp from 'lib/mailchimp.js'
import React from 'react'
import styled from 'styled-components/macro'

const url = process.env.REACT_APP_MAILCHIMP

const Hero = () => {
  return (
    <HeroWrapper>
      <DivRow>
        <DivInfoSide>
          <WhitelistHeader>
            SECURE YOUR <span>PRESALE</span> MINTING SLOT
          </WhitelistHeader>
          <ItemAnswer>
            The presale minting slots are reserved for the winners of the whitelisting Gleam contest which will be
            revealed soon. There&apos;s a reserved limited amount of whitelist slots for dedicated community members. To
            apply for the Drip-Lab Whitelist, submit your ETH address and desired mint quantity via the form.
          </ItemAnswer>
          <ItemAnswer>
            Submitted ETH wallet addresses will be validated on the day of the presale, and if the minting is not
            sufficient, will be removed from the whitelist.
          </ItemAnswer>
        </DivInfoSide>
        <DivFormSide>
          <Mailchimp
            action={url}
            className={'MailchimpStyle'}
            fields={[
              {
                key: 'email',
                name: 'EMAIL',
                placeholder: 'Enter your email',
                type: 'email',
                required: true,
              },
              {
                key: 'wallet',
                name: 'WALLET',
                placeholder: 'Paste wallet address',
                type: 'text',
                required: false,
              },
              {
                key: 'discord',
                name: 'DISCORD',
                placeholder: 'Insert Discord handle...',
                type: 'text',
                required: false,
              },
              {
                key: 'quantity',
                name: 'QUANTITY',
                placeholder: 'How many would you buy?',
                type: 'number',
                required: false,
              },
            ]}
          />
        </DivFormSide>
      </DivRow>
    </HeroWrapper>
  )
}

export default Hero
const WhitelistHeader = styled.h2`
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 58px;
  line-height: 62px;
  color: ${({ theme }) => theme.white};
  & > span {
    color: ${({ theme }) => theme.green2};
  }
  @media screen and (min-width: 600px) {
    font-size: calc(54px + 16 * ((100vw - 600px) / 600));
    line-height: calc(60px + 16 * ((100vw - 600px) / 600));
  }
  @media screen and (min-width: 1200px) {
    font-size: 74px;
    line-height: 82px;
  }
  @media screen and (max-width: 600px) {
    font-size: 54px;
    line-height: 60px;
    padding-bottom: 20px;
    padding-top: 20px;
  }
`
const ItemAnswer = styled.p`
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;
  color: ${({ theme }) => theme.white};
  padding-right: 30px;
`

const HeroWrapper = styled.div`
  padding-top: 64px;
  padding-bottom: 116px;
  width: 100%;
  max-width: 1100px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
   padding-top: 28px;
   padding-bottom: 60px;
  `};
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px black inset !important;
    -webkit-text-fill-color: white;
  }
  color: white;
`

const DivRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`
const DivInfoSide = styled.div`
  flex: 2 1 0;
  padding-left: 16px;
  padding-right: 16px;
  @media (max-width: 700px) {
    text-align: center;
    flex: 0 0 100%;
    max-width: 100%;
    padding-right: 16px;
  }
`

const DivFormSide = styled.div`
  flex: 1 1 0;
  padding-left: 48px;
  padding-right: 16px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  ${({ theme }) => theme.mediaWidth.upToMedium`
      flex: 0 0 50%;
      max-width: 50%;
  `};
  @media (max-width: 700px) {
    flex: 0 0 100%;
    max-width: 100%;
    margin-top: 28px;
    padding-left: 16px;
  }
`
