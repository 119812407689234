import { CHAIN_INFO } from 'constants/chainInfo'
import { ChainIds } from 'constants/chains'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import { useBalance } from 'hooks/useBalance'
import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { Text } from 'rebass'
import styled from 'styled-components/macro'

import logo from '../../assets/logo/logo.png'
import { weiToEther } from '../../utils/balance'
import IconPortal from '../IconPortal'
import Web3Status from '../Web3Status'

const Title = styled.a`
  // display: flex;
  // align-items: center;
  // pointer-events: auto;
  // justify-self: flex-start;
  // margin-right: 12px;
  // height: 100%;
  // ${({ theme }) => theme.mediaWidth.upToSmall`
  //   justify-self: center;
  // `};
  :hover {
    cursor: pointer;
  }
`

const HeaderElement = styled.div`
  display: flex;
  align-items: center;

  &:not(:first-child) {
    margin-left: 0.5em;
  }

  /* addresses safari's lack of support for "gap" */
  & > *:not(:first-child) {
    margin-left: 8px;
  }

  ${({ theme }) => theme.mediaWidth.upToMedium`
    align-items: center;
  `};
`
const AccountElement = styled.div<{ active: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  white-space: nowrap;
  width: 100%;
  height: 42px;
  padding-left: ${({ active }) => (!active ? 0 : '14px')};
  border: 1px solid rgba(173, 255, 8, 1);
  color: rgba(173, 255, 8, 1);
  font-family: 'Montserrat', serif;
  border-radius: 4px;
`
const BalanceText = styled(Text)`
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    display: none;
  `};
`
const HeaderControls = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-self: flex-end;
`

const Navbar = () => {
  const [nav1, setNav1] = useState(true)
  const { balance } = useBalance()
  const { account, chainId } = useActiveWeb3React()
  const {
    nativeCurrency: { symbol: nativeCurrencySymbol },
  } = CHAIN_INFO[chainId ? chainId : ChainIds.RINKEBY]
  const location = useLocation()

  useEffect(() => {
    if (location.pathname === '/whitelist') {
      setNav1(false)
    } else {
      setNav1(true)
    }
  }, [location.pathname])

  return (
    <>
      <Nav nav1={nav1}>
        <LogoDiv>
          <Title href="https://drip-lab.com" target="_blank" rel="noreferrer">
            <IconPortal SrcImageIcon={logo} widthIcon={'100%'} heightIcon={'100%'} />
          </Title>
        </LogoDiv>
        {nav1 && (
          <RowDiv>
            <HeaderControls>
              <HeaderElement>
                <AccountElement active={!!account}>
                  {account && balance ? (
                    <BalanceText
                      style={{ flexShrink: 0, userSelect: 'none' }}
                      pl="0.75rem"
                      pr="1.75rem"
                      fontWeight={500}
                    >
                      {weiToEther(balance, 3)} {nativeCurrencySymbol}
                    </BalanceText>
                  ) : null}
                  <Web3Status />
                </AccountElement>
              </HeaderElement>
            </HeaderControls>
          </RowDiv>
        )}
      </Nav>
    </>
  )
}

export default Navbar

const Nav = styled.div<{ nav1?: boolean }>`
  padding: 0 2rem;
  display: flex;
  justify-content: ${({ nav1 }) => (nav1 ? 'space-between' : 'center')};
  align-items: center;
  flex-wrap: wrap;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  max-width: 1200px;
`

const RowDiv = styled.div`
  display: flex;
  justify-content: flex-end;
  flex: 2;
  @media (max-width: 575px) {
    flex: 1;
  }
`
const LogoDiv = styled.div`
  // padding: 1rem 0;
  color: #7b7fda;
  text-decoration: none;
  height: 100%;
  flex: 1;
  @media (max-width: 575px) {
    flex: 1;
  }
`
