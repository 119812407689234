import React from 'react'
import styled from 'styled-components/macro'

import Modal from '../index'
interface Props {
  isOpen: boolean
  onDismiss: () => void
  maxWidth?: number | undefined
  data?: {
    type: string | undefined
    message: string | undefined
  }
}
export interface DataModalLoading {
  type: string
  amount?: string | number
}
const ModalLoading = (props: Props) => {
  const { isOpen, onDismiss, data, maxWidth } = props
  return (
    <Modal isOpen={isOpen} onDismiss={onDismiss} maxWidth={maxWidth}>
      <ModalContent>
        {data && data.type === 'mint' ? (
          <>
            <ModalHeader>MINTING IN PROGRESS</ModalHeader>
            <ModalTextAnimated>{data.message}</ModalTextAnimated>
          </>
        ) : (
          <>
            <ModalHeader>Loading...</ModalHeader>
            <ModalTextAnimated>Please wait for a few seconds...</ModalTextAnimated>
          </>
        )}
      </ModalContent>
    </Modal>
  )
}
const ModalContent = styled.div`
  padding: 30px;
  width: 100%;
  background: ${({ theme }) => theme.green2};
`
const ModalHeader = styled.h3`
  font-family: 'Montserrat', sans-serif;
  color: ${({ theme }) => theme.black};
  text-align: center;
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 49px;
`
const ModalTextAnimated = styled.p`
  font-family: 'Montserrat', sans-serif;
  color: ${({ theme }) => theme.black};
  text-align: center;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 29px;
  @keyframes pulse {
    0% {
      transform: scale(0.9);
      color: transparent;
    }
    100% {
      transform: scale(1.03);
      color: black;
    }
  }
  animation: pulse 1.5s infinite;
`
export default ModalLoading
