import JSBI from 'jsbi'
import { useEffect, useState } from 'react'

import { MintingStatus, SaleStatus } from '../constants/crowdsale'
import useActiveWeb3React from './useActiveWeb3React'
import { useBalance } from './useBalance'
import { useCrowdsaleContract } from './useCrowdsaleContract'
import { useSaleInformation } from './useSaleInformation'
import { useWhitelist } from './useWhitelist'

export function useMinting() {
  const { account } = useActiveWeb3React()
  const { saleInformation, updateSaleInformation } = useSaleInformation()
  const { balance, updateBalance, numberMinted } = useBalance()
  const crowdsaleContract = useCrowdsaleContract()
  const [buyableAmount, setBuyableAmount] = useState(0) // max amount of tokens that can be minted
  const [mintingAmount, setMintingAmount] = useState(1) // minting amount to be set up
  const { isWhitelisted, hexProof } = useWhitelist()

  const [lastMintingResult, setLastMintingResult] = useState({
    status: MintingStatus.NO_MINTING,
    lastMinted: 0,
  })

  const increaseMintingAmount = () => {
    setMintingAmount(buyableAmount > mintingAmount ? mintingAmount + 1 : buyableAmount)
  }

  const decreaseMintingAmount = () => {
    setMintingAmount(mintingAmount > 1 && buyableAmount > 1 ? mintingAmount - 1 : mintingAmount)
  }

  const canMint = () => {
    return buyableAmount > 0
  }
  const mintMore = () => {
    updateSaleInformation()
    setLastMintingResult({
      status: MintingStatus.NO_MINTING,
      lastMinted: 0,
    })
  }

  useEffect(() => {
    if (
      balance &&
      saleInformation &&
      saleInformation.maxSupply &&
      saleInformation.preSalePrice &&
      saleInformation.salePrice &&
      saleInformation.saleStatus
    ) {
      const totalMinted = saleInformation?.totalMinted ? saleInformation?.totalMinted : 0
      const remainingSupply = saleInformation.maxSupply - totalMinted
      const jsbiBalance = JSBI.BigInt(balance)

      if (
        // pre sale ongoing
        JSBI.greaterThan(jsbiBalance, saleInformation.preSalePrice) &&
        saleInformation.saleStatus === SaleStatus.PRE_SALE &&
        isWhitelisted
      ) {
        if (account) {
          if (
            saleInformation.preSalePrice &&
            saleInformation.maxPresale &&
            numberMinted !== undefined &&
            numberMinted.toNumber() >= 0
          ) {
            let mintableAmount = JSBI.subtract(JSBI.BigInt(saleInformation.maxPresale), JSBI.BigInt(numberMinted))
            const fullBuyingCapacity = JSBI.divide(jsbiBalance, saleInformation.preSalePrice)
            mintableAmount = JSBI.greaterThan(fullBuyingCapacity, mintableAmount) ? mintableAmount : fullBuyingCapacity
            setBuyableAmount(
              remainingSupply > JSBI.toNumber(mintableAmount) ? JSBI.toNumber(mintableAmount) : remainingSupply
            )
            // console.log('mintableAmount', mintableAmount)
            // console.log('fullBuyingCapacity', fullBuyingCapacity)
            // console.log(
            //   'buyableAmount',
            //   remainingSupply > JSBI.toNumber(mintableAmount) ? JSBI.toNumber(mintableAmount) : remainingSupply
            // )
          } else {
            setBuyableAmount(0)
          }
        } else {
          setBuyableAmount(0)
        }
      } else if (
        // sale ongoing
        JSBI.greaterThan(jsbiBalance, saleInformation.salePrice) &&
        saleInformation?.saleStatus === SaleStatus.SALE
      ) {
        const fullBuyingCapacity = JSBI.toNumber(JSBI.divide(jsbiBalance, saleInformation.salePrice))
        setBuyableAmount(remainingSupply > fullBuyingCapacity ? fullBuyingCapacity : remainingSupply)
      } else {
        setBuyableAmount(0)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [numberMinted, balance, saleInformation])

  const mint = async () => {
    if (crowdsaleContract && saleInformation.saleStatus !== undefined) {
      try {
        let tx
        if (saleInformation.saleStatus === SaleStatus.PRE_SALE && isWhitelisted && saleInformation.preSalePrice) {
          if (hexProof === undefined) throw Error('hexProof is undefined')
          tx = await crowdsaleContract.preMint(mintingAmount, hexProof, {
            value: JSBI.multiply(saleInformation.preSalePrice, JSBI.BigInt(mintingAmount)).toString(),
          })
        } else if (saleInformation.saleStatus === SaleStatus.SALE && saleInformation.salePrice) {
          tx = await crowdsaleContract.mint(mintingAmount, {
            value: JSBI.multiply(saleInformation.salePrice, JSBI.BigInt(mintingAmount)).toString(),
          })
        } else {
          throw Error('Unexpected SaleStatus: ' + SaleStatus[saleInformation.saleStatus])
        }

        setLastMintingResult({
          status: MintingStatus.MINTING,
          lastMinted: mintingAmount,
        })

        tx.wait(1).then((receipt) => {
          setLastMintingResult({
            status: MintingStatus.FINISHED,
            lastMinted: mintingAmount,
          })
          updateSaleInformation()
          updateBalance()
        })
        // .finally(() => {
        //   setTimeout(() => {}, 2000)
        // })
      } catch (e) {
        if (e.code !== 4001) {
          console.log(e)
          setLastMintingResult({
            status: MintingStatus.ERROR,
            lastMinted: 0,
          })
        }
        updateSaleInformation()
      }
    } else {
      throw Error('Unexpected SaleStatus: ' + undefined)
    }
  }

  return {
    buyableAmount,
    mintingAmount,
    setMintingAmount,
    canMint,
    mint,
    increaseMintingAmount,
    decreaseMintingAmount,
    lastMintingResult,
    mintMore,
  }
}
