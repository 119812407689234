import React, { useState } from 'react'
import styled, { css } from 'styled-components/macro'

import ImageCorrection from '../../../assets/images/image_correction.png'
import dataDescription from './data.json'

const SectionDescription = () => {
  const { navItems, tabPanes } = dataDescription

  const [currentTab, setCurrentTab] = useState<string | number>('description')
  const handleClickTab = (id: string | number) => {
    setCurrentTab(id)
  }
  return (
    <SectionWrapper>
      <Main>
        <DivRow>
          <DivLeft>
            <DivImageBox>
              <img src={ImageCorrection} alt={'Image_Correction'} />
            </DivImageBox>
          </DivLeft>
          <DivRight>
            <DivRightContent>
              <NavTabs>
                {navItems.map((item, index) => {
                  return (
                    <NavItem
                      key={index}
                      id={item.id}
                      onClick={() => handleClickTab(item.id)}
                      active={currentTab === item.id}
                    >
                      {item.name}
                    </NavItem>
                  )
                })}
              </NavTabs>
              <TabContent>
                {tabPanes.map((tabPane, index) => {
                  return (
                    <TabPane key={index}>
                      {currentTab === tabPane.id && (
                        <TabPaneContent key={index} dangerouslySetInnerHTML={{ __html: tabPane.content }} />
                      )}
                    </TabPane>
                  )
                })}
              </TabContent>
            </DivRightContent>
          </DivRight>
        </DivRow>
      </Main>
    </SectionWrapper>
  )
}
const SectionWrapper = styled.div`
  display: block;
  width: 100%;
  padding-bottom: 94px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    padding-bottom: 60px;
  `};
`
const Main = styled.div`
  padding-left: 16px;
  padding-right: 16px;
`
const DivRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-left: -16px;
  margin-right: -16px;
`
const DivLeft = styled.div`
  flex: 0 0 41.66667%;
  max-width: 41.66667%;
  padding-left: 16px;
  padding-right: 16px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    display: none;
  `};
`
const DivImageBox = styled.div`
  & > img {
    display: block;
    width: 100%;
    vertical-align: middle;
    min-height: 1px;
  }
`
const DivRight = styled.div`
  flex: 0 0 58.33333%;
  max-width: 58.33333%;
  padding-left: 16px;
  padding-right: 16px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
      flex: 0 0 100%;
      max-width: 100%;
  `};
`
const DivRightContent = styled.div``
const NavTabs = styled.div`
  display: flex;
  align-items: center;
`
const NavItem = styled.p<{ active?: boolean }>`
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 21px;
  line-height: 1.4419;
  color: ${({ theme }) => theme.green2};
  letter-spacing: 0.9px;
  padding: 0 27px;
  cursor: pointer;
  &:first-of-type {
    padding-left: 0;
  }
  ${({ active }) =>
    active &&
    css`
      font-weight: 700;
      font-size: 24px;
      ::after {
        content: '';
        border-bottom: 3px solid ${({ theme }) => theme.green2};
        width: 100%;
        display: block;
      }
    `}
  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 16px;
    padding: 0 20px;
  `};
`
const TabContent = styled.div`
  margin-top: 42px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    margin-top: 32px;
  `};
`
const TabPane = styled.div`
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 1.76;
  letter-spacing: 0.9px;
`
const TabPaneContent = styled.div<{ flex?: boolean }>`
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 1.76;
  letter-spacing: 0.9px;
  white-space: pre-wrap;
  color: ${({ theme }) => theme.text1};
  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 16px;
  `};
  ${({ flex }) =>
    flex &&
    css`
      display: flex;
      flex-wrap: wrap;
      p {
        flex: 0 0 50%;
        max-width: 50%;
        margin: 0;
      }
      p:nth-of-type(odd) {
        padding-right: 13px;
      }
      p:nth-of-type(even) {
        padding-left: 13px;
      }
    `}
`
export default SectionDescription
