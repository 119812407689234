import { SaleStatus } from 'constants/crowdsale'
import { useSaleInformation } from 'hooks/useSaleInformation'
import React from 'react'
import styled from 'styled-components/macro'

import { Countdown } from '../Countdown'

export default function AwaitingDrop() {
  const { saleInformation } = useSaleInformation()
  return (
    <div style={{ width: '100%' }}>
      {saleInformation.saleStatus === SaleStatus.PRE_SALE_ENDED ? (
        <TimerHeader>
          PUBLIC SALE <span>STARTS IN</span>
        </TimerHeader>
      ) : (
        <TimerHeader>
          Time left <span>Until drop</span>
        </TimerHeader>
      )}

      <Countdown
        exactStart={new Date(process.env.REACT_APP_TIME_LEFT_TO_PRESALE_START ?? '2022-06-29T00:00:00.000+00:00')}
        exactEnd={new Date(process.env.REACT_APP_TIME_LEFT_TO_PRESALE_END ?? '2022-06-29T00:00:00.000+00:00')}
        messageOngoing={''}
        messageBeginIn={' '}
        messageEnded={''}
      />
    </div>
  )
}

const TimerHeader = styled.h2`
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 70px;
  line-height: 98px;
  text-align: center;
  text-transform: uppercase;
  margin: 0;
  & > span {
    font-weight: 600;
  }
  @media (max-width: 575px) {
    font-size: 35px;
    line-height: 58px;
  }
`
