import jsonp from 'jsonp'
import PropTypes from 'prop-types'
// eslint-disable-next-line no-use-before-define
import React from 'react'
import styled from 'styled-components/macro'

import SubmitImg from '../../src/assets/navlink/submitBtn.png'
import IconPortal from '.././components/IconPortal'

class Mailchimp extends React.Component {
  state = {}

  handleSubmit(evt) {
    evt.preventDefault()
    const { fields, action } = this.props
    const values = fields
      .map((field) => {
        return `${field.name}=${encodeURIComponent(this.state[field.name])}`
      })
      .join('&')
    const path = `${action}&${values}`
    const url = path.replace('/post?', '/post-json?')
    // eslint-disable-next-line no-useless-escape
    const regex = /^([\w_\.\-\+])+\@([\w\-]+\.)+([\w]{2,10})+$/
    const email = this.state['EMAIL']
    return !regex.test(email) ? this.setState({ status: 'empty' }) : this.sendData(url)
  }

  sendData(url) {
    this.setState({ status: 'sending' })
    jsonp(url, { param: 'c' }, (err, data) => {
      if (data.msg.includes('already subscribed')) {
        this.setState({ status: 'duplicate' })
      } else if (err) {
        this.setState({ status: 'error' })
      } else if (data.result !== 'success') {
        this.setState({ status: 'error' })
      } else {
        this.setState({ status: 'success' })
      }
    })
  }

  render() {
    const { fields, styles, className, buttonClassName } = this.props
    const messages = {
      ...Mailchimp.defaultProps.messages,
      ...this.props.messages,
    }
    const { status } = this.state
    return (
      <form onSubmit={this.handleSubmit.bind(this)} className={className}>
        {fields.map((input, ix) => (
          <MailchimpField key={ix}>
            <InputLabel htmlFor={input.name}>{input.name}</InputLabel>
            <InputNumberControl
              {...input}
              key={input.name}
              onChange={({ target }) => this.setState({ [input.name]: target.value })}
              defaultValue={this.state[input.name]}
            />
          </MailchimpField>
        ))}
        <ButtonBuy
          disabled={status === 'sending' || status === 'success'}
          type="submit"
          className={buttonClassName + ' grow'}
        >
          <IconPortal SrcImageIcon={SubmitImg} widthIcon={'auto'} heightIcon={'auto'} />

          {/* {messages.button} */}
        </ButtonBuy>
        <TextAlert className="msg-alert">
          {status === 'sending' && <p style={styles.sendingMsg}>{messages.sending}</p>}
          {status === 'success' && <p style={styles.successMsg}>{messages.success}</p>}
          {status === 'duplicate' && <p style={styles.duplicateMsg}>{messages.duplicate}</p>}
          {status === 'empty' && <p style={styles.errorMsg}>{messages.empty}</p>}
          {status === 'error' && <p style={styles.errorMsg}>{messages.error}</p>}
        </TextAlert>
      </form>
    )
  }
}

Mailchimp.defaultProps = {
  messages: {
    sending: 'Sending...',
    success: 'Thank you for subscribing!',
    error: 'An unexpected internal error has occurred.',
    empty: 'You must complete the form!',
    duplicate: 'Too many subscribe attempts for this email address',
    button: 'Submit!',
  },
  buttonClassName: '',
  styles: {
    sendingMsg: {
      color: '#ADFF08',
    },
    successMsg: {
      color: '#ADFF08',
    },
    duplicateMsg: {
      color: '#EE5A24',
    },
    errorMsg: {
      color: '#ec052b',
    },
  },
}

Mailchimp.propTypes = {
  action: PropTypes.string,
  messages: PropTypes.object,
  fields: PropTypes.array,
  styles: PropTypes.object,
  className: PropTypes.string,
  buttonClassName: PropTypes.string,
}

export default Mailchimp
const TextAlert = styled.div`
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;
  color: ${({ theme }) => theme.white};
  margin: 10px 0;
  align-self: flex-end;
`
const InputLabel = styled.label`
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 25px;
  color: ${({ theme }) => theme.white};
`
const InputNumberControl = styled.input`
  margin-top: 5px;
  transition: all 0.2s ease-in-out;
  width: 100%;
  outline: none;
  padding: 8px 14px;
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 1.4419;
  border-radius: 4px;
  text-align: left !important;
  color: white;
  letter-spacing: 0.9px;
  background-color: transparent;
  border-color: ${({ theme }) => theme.green2};
  -moz-appearance: textfield;
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  &::placeholder {
    font-weight: 400;
    font-size: 16px;
    color: rgba(255, 255, 255, 0.6);
  }
  &:focus {
    border-color: white;
    color: ${({ theme }) => theme.green2};
    &::placeholder {
      color: transparent;
    }
  }
  @media (max-width: 575px) {
    text-align: center;
    font-size: 16px;
    padding: 8px;
  }
`

const MailchimpField = styled.div`
  width: 100%;
  padding-top: 10px;
`

const ButtonBuy = styled.button`
  align-self: flex-end;
  cursor: pointer;
  // margin-left: 145px;
  max-width: 142px;
  outline: none;
  border: none;
  background-color: ${({ theme }) => theme.green2};
  padding: 9px 23px;
  border-radius: 5px;
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  line-height: 1.4419;
  color: ${({ theme }) => theme.textDark};
  letter-spacing: 1.05px;
  text-transform: uppercase;
  margin-top: 30px;

  &:disabled {
    background-color: ${({ theme }) => theme.textGray};
    cursor: not-allowed;
  }
  ${({ theme }) => theme.mediaWidth.upToLarge`
    margin-left: 32px;
    margin-top: 16px;
  `};
  ${({ theme }) => theme.mediaWidth.upToMedium`
      margin-left: 16px;
  `};
  @media (max-width: 575px) {
    margin-left: 0;
    max-width: none;
    font-size: 18px;
  }
`
