import ErrorBoundary from 'components/ErrorBoundary'
import Navbar from 'components/Header/Navbar'
import Loader from 'components/Loader'
import Popups from 'components/Popups'
import Web3ReactManager from 'components/Web3ReactManager'
import { Suspense } from 'react'
import { Route, Switch } from 'react-router-dom'
import styled from 'styled-components/macro'

import Footer from '../components/Footer'
import Mint from './mint'
import Terms from './terms'
import Whitelist from './whitelist'

let prevScrollpos = window.pageYOffset

window.onscroll = () => {
  const currentScrollPos = window.pageYOffset
  const c: any = document.querySelector('.navbarDiv')

  if (prevScrollpos > 0) {
    if (prevScrollpos > currentScrollPos) {
      c.style.top = '0'
    } else {
      c.style.top = '-100px'
    }
  }

  prevScrollpos = currentScrollPos
}
const AppWrapper = styled.div`
  display: flex;
  flex-flow: column;
  align-items: center;
`

const BodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 120px 16px 0 16px;
  align-items: center;
  flex: 1;
  z-index: 1;
  max-width: 1200px;
  overflow: hidden;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    padding: 4rem 8px 16px 8px;
  `};
`

const HeaderWrapper = styled.div`
  ${({ theme }) => theme.flexRowNoWrap}
  width: 100%;
  height: 100px;
  background: black;
  justify-content: center;
  position: fixed;
  top: 0;
  z-index: 2;
  transition: top 0.3s;
`
const FooterWrapper = styled.div`
  max-width: 1400px;
  width: 100%;
  padding-left: 16px;
  padding-right: 16px;
`

export default function App() {
  return (
    <ErrorBoundary>
      <Route />
      <Web3ReactManager>
        <AppWrapper>
          <HeaderWrapper className="navbarDiv">
            <Navbar />
          </HeaderWrapper>
          <BodyWrapper>
            <Popups />
            <Suspense fallback={<Loader />}>
              <Switch>
                <Route strict exact path="/" component={Mint} />
                <Route strict path="/mint" component={Mint} />
                <Route strict path="/terms" component={Terms} />
                <Route strict path="/whitelist" component={Whitelist} />
              </Switch>
            </Suspense>
          </BodyWrapper>
          <FooterWrapper>
            <Footer />
          </FooterWrapper>
        </AppWrapper>
      </Web3ReactManager>
    </ErrorBoundary>
  )
}
