import { SaleStatus } from 'constants/crowdsale'
import JSBI from 'jsbi'
import { useEffect, useState } from 'react'

import { useCrowdsaleContract } from './useCrowdsaleContract'

export function useSaleInformation() {
  const crowdsaleContract = useCrowdsaleContract(false)

  const [saleInformation, setSaleInformation] = useState<{
    saleStatus?: SaleStatus
    maxSupply?: number
    maxSupply_Presale?: number
    totalMinted?: number
    maxPresale?: number
    maxSale?: number
    preSalePrice?: JSBI
    salePrice?: JSBI
  }>({})

  const updateSaleInformation = () => {
    if (crowdsaleContract) {
      try {
        crowdsaleContract.getSaleInformation().then((saleInfo) => {
          try {
            setSaleInformation({
              saleStatus: saleInfo[0],
              maxSupply: saleInfo[1].toNumber(),
              maxSupply_Presale: saleInfo[2].toNumber(),
              totalMinted: saleInfo[3].toNumber(),
              maxPresale: saleInfo[4].toNumber(),
              maxSale: saleInfo[5].toNumber(),
              preSalePrice: JSBI.BigInt(saleInfo[6]),
              salePrice: JSBI.BigInt(saleInfo[7]),
            })
          } catch (er) {
            console.log(er)
          }
        })
      } catch (e) {
        console.log(e)
      }
    }
  }

  useEffect(() => {
    updateSaleInformation()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return {
    saleInformation,
    updateSaleInformation,
  }
}
