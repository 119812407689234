import React from 'react'
import styled from 'styled-components/macro'

import Discord from '../../assets/images/Drip_Discord.png'
import Instagram from '../../assets/images/Drip_Instagram.png'
import Twitter from '../../assets/images/Drip_Twitter.png'
import Logo from '../../assets/logo/logo.png'
import Another from '../../assets/svg/img_Another.svg'
import Olmetex from '../../assets/svg/img_Olmetex.svg'
import IconPortal from '../IconPortal'
const Footer = () => {
  return (
    <FooterWrapper>
      <Main>
        <DivFlex>
          <DivLeft>
            <Name>POWERED BY</Name>
            <DivLeftImage>
              <DivImageOlmetex>
                <LogoLink href={''} target="_blank" rel="noreferrer">
                  <img src={Olmetex} alt={'Olmetex'} className={'grow'} />
                </LogoLink>
              </DivImageOlmetex>
              <DivImageAnother>
                <SocialLink href={''} target="_blank" rel="noreferrer">
                  <img src={Another} alt={'Another'} className={'grow'} />
                </SocialLink>
              </DivImageAnother>
            </DivLeftImage>
          </DivLeft>
          <DivCenter>
            <LinkLogo href={'https://drip-lab.com'} target="_blank" rel="noreferrer">
              <IconPortal SrcImageIcon={Logo} widthIcon={'100%'} heightIcon={'100%'} />
            </LinkLogo>
          </DivCenter>
          <DivRight>
            <ListSocial>
              <SocialItem className={'grow'}>
                <SocialLink href={''} target="_blank" rel="noreferrer">
                  <img src={Discord} alt={'Discord'} />
                </SocialLink>
              </SocialItem>
              <SocialItem className={'grow'}>
                <SocialLink href={'https://www.instagram.com/drip_lab_official'} target="_blank" rel="noreferrer">
                  <img src={Instagram} alt={'Instagram'} />
                </SocialLink>
              </SocialItem>
              <SocialItem className={'grow'}>
                <SocialLink href={'https://twitter.com/Drip_Lab_real'} target="_blank" rel="noreferrer">
                  <img src={Twitter} alt={'Twitter'} />
                </SocialLink>
              </SocialItem>
            </ListSocial>
            <Name>FOLLOW US</Name>
          </DivRight>
        </DivFlex>
        <DivFlex>
          <DivCenter>
            <Nocopyright>© Drip-Lab. All rights reserved.</Nocopyright>
          </DivCenter>
          <DivLeft>
            <Terms
              href="https://drip-lab.com/wp-content/uploads/2022/06/DRIP-LAB-TERMS-CONDITIONS.pdf"
              target="_blank"
              rel="noreferrer"
            >
              Terms & Conditions
            </Terms>
          </DivLeft>
        </DivFlex>
      </Main>
    </FooterWrapper>
  )
}
const FooterWrapper = styled.div`
  width: 100%;
  padding-top: 65px;
  padding-bottom: 45px;
`
const Main = styled.div``
const DivFlex = styled.div`
  display: flex;
  justify-content: space-between;
  height: 100px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    flex-wrap:wrap;
    justify-content: flex-start;
    flex-direction: column;
    align-items:center;
    height: auto;
  `};
`
const DivLeft = styled.div`
  display: flex;
  align-items: center;

  ${({ theme }) => theme.mediaWidth.upToSmall`
      flex-direction:column;
      order: 3;
  `};
`
const Name = styled.p`
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 1.2;
  color: ${({ theme }) => theme.text1};
  margin: 0;
  ${({ theme }) => theme.mediaWidth.upToLarge`
    font-size: 12px;
  `};
`
const DivLeftImage = styled.div`
  display: flex;
  align-items: center;
  margin-left: 26px;
  ${({ theme }) => theme.mediaWidth.upToLarge`
    margin-left: 16px;
  `};
  ${({ theme }) => theme.mediaWidth.upToSmall`
       margin-left: 0;
       margin-top: 20px;
  `};
`
const DivImageOlmetex = styled.div`
  margin: 0 26px 0 0;
  img {
    display: block;
    width: 65px;
    height: 50px;
    object-fit: cover;
    vertical-align: middle;
    min-height: 1px;
    ${({ theme }) => theme.mediaWidth.upToLarge`
     width: 58px;
     height: 45px;
     margin-right: 22px;
  `};
    ${({ theme }) => theme.mediaWidth.upToSmall`
     width: 43px;
     height: 33px;
  `};
  }
`
const DivImageAnother = styled.div`
  img {
    display: block;
    width: 54px;
    height: 54px;
    object-fit: cover;
    vertical-align: middle;
    min-height: 1px;
    ${({ theme }) => theme.mediaWidth.upToLarge`
     width: 40px;
     height: 40px;
  `};
    ${({ theme }) => theme.mediaWidth.upToSmall`
     width: 36px;
     height: 36px;
  `};
  }
`
const DivCenter = styled.div`
  padding-left: 10px;
  padding-right: 10px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
     order: 1;
  `};
`
const LinkLogo = styled.a`
  text-decoration: none;
`
const DivRight = styled.div`
  display: flex;
  align-items: center;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    order: 2;
    & > p {
      display: none;
    }
    margin: 30px 0;
  `};
`
const ListSocial = styled.ul`
  list-style: none;
  padding-left: 0;
  display: flex;
  align-items: center;
  margin: 0 26px 0 0;
  ${({ theme }) => theme.mediaWidth.upToLarge`
    margin-right: 16px;
  `};
  ${({ theme }) => theme.mediaWidth.upToSmall`
     margin: 0;
  `};
`
const SocialItem = styled.li`
  padding: 0 12px;
  &:first-of-type {
    padding-left: 0;
  }
  &:last-of-type {
    padding-right: 0;
  }
  ${({ theme }) => theme.mediaWidth.upToLarge`
     padding: 0 8px;
  `};
`
const LogoLink = styled.a`
  text-decoration: none;
  display: block;
  & img {
    display: block;
    // width: 37px;
    // height: 37px;
    object-fit: cover;
    vertical-align: middle;
    min-height: 1px;

    ${({ theme }) => theme.mediaWidth.upToLarge`
     width: 36px;
     height: 36px;
  `};
  }
`
const SocialLink = styled.a`
  text-decoration: none;
  display: block;
  & img {
    display: block;
    width: 37px;
    height: 37px;
    object-fit: cover;
    vertical-align: middle;
    min-height: 1px;

    ${({ theme }) => theme.mediaWidth.upToLarge`
     width: 36px;
     height: 36px;
  `};
  }
`
const Nocopyright = styled.p`
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  color: ${({ theme }) => theme.text1};
  margin-top: 40px;
  margin-bottom: 0;
  ${({ theme }) => theme.mediaWidth.upToSmall`
     margin-top: 30px;
     font-size: 16px;
     line-height: 1.5;
  `};
`
const Terms = styled.a`
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  color: ${({ theme }) => theme.text1};
  margin-top: 40px;
  margin-bottom: 0;
  text-decoration: none;
  ${({ theme }) => theme.mediaWidth.upToSmall`
     margin-top: 30px;
     font-size: 16px;
     line-height: 1.5;
  `};
`
export default Footer
