import { AutoColumn } from 'components/Column'
import styled from 'styled-components/macro'

const PageWrapper = styled(AutoColumn)`
  max-width: 640px;
  width: 100%;
`

// const TopSection = styled(AutoColumn)`
//   max-width: 720px;
//   width: 100%;
// `

export default function Terms() {
  return (
    <PageWrapper gap="lg" justify="center">
      <h1>Terms of service</h1>
    </PageWrapper>
  )
}
