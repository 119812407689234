import { useEffect, useMemo, useState } from 'react'
import styled from 'styled-components/macro'
import { ThemedText } from 'theme'

const MINUTE = 60
const HOUR = MINUTE * 60
const DAY = HOUR * 24

export function Countdown({
  exactStart,
  exactEnd,
  messageBeginIn,
  messageOngoing,
  messageEnded,
}: {
  exactStart?: Date
  exactEnd: Date
  messageBeginIn?: string
  messageOngoing?: string
  messageEnded?: string
}) {
  // get end/beginning times
  const end = useMemo(() => (exactEnd ? Math.floor(exactEnd.getTime() / 1000) : 0), [exactEnd])

  // get current time
  const [time, setTime] = useState(() => Math.floor(Date.now() / 1000))
  useEffect((): (() => void) | void => {
    // we only need to tick if rewards haven't ended yet
    if (time <= end) {
      const timeout = setTimeout(() => setTime(Math.floor(Date.now() / 1000)), 1000)
      return () => {
        clearTimeout(timeout)
      }
    }
  }, [time, end])

  const timeUntilGenesis = exactStart ? exactStart.getTime() / 1000 - time : 0
  const timeUntilEnd = end - time

  let timeRemaining: number
  let message: string
  if (timeUntilGenesis > 0) {
    message = messageBeginIn ?? ''
    timeRemaining = timeUntilGenesis
  } else {
    const ongoing = timeUntilEnd >= 0
    if (ongoing) {
      message = messageOngoing ?? ''
      timeRemaining = timeUntilEnd
    } else {
      message = messageEnded ?? ''
      timeRemaining = Infinity
    }
  }

  const days = (timeRemaining - (timeRemaining % DAY)) / DAY
  timeRemaining -= days * DAY
  const hours = (timeRemaining - (timeRemaining % HOUR)) / HOUR
  timeRemaining -= hours * HOUR
  const minutes = (timeRemaining - (timeRemaining % MINUTE)) / MINUTE
  timeRemaining -= minutes * MINUTE
  const seconds = timeRemaining

  return (
    <ThemedText.Black fontWeight={400}>
      {message}{' '}
      {Number.isFinite(timeRemaining) && (
        <>
          <TimeClock>
            <div className="countDiv">
              {days.toString().padStart(2, '0')} <p>days</p>
            </div>
            <div className="countDiv">
              {hours.toString().padStart(2, '0')}
              <p>hrs</p>
            </div>{' '}
            <div className="countDiv">
              {minutes.toString().padStart(2, '0')}
              <p>mins</p>
            </div>
            <div className="countDiv">
              {seconds.toString().padStart(2, '0')} <p>sec</p>
            </div>
          </TimeClock>
          {/* <TimeClock>
            {`${days}  ${hours.toString().padStart(2, '0')}  ${minutes.toString().padStart(2, '0')}  ${seconds
              .toString()
              .padStart(2, '0')}`}
          </TimeClock> */}
        </>
      )}
    </ThemedText.Black>
  )
}

const TimeClock = styled.h2`
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 64px;
  line-height: 127.5%;
  text-align: center;
  display: flex;
  justify-content: space-between;
  color: ${({ theme }) => theme.black};
  text-shadow: -1px -1px 0 #9fff0e, 1px -1px 0 #9fff0e, -1px 1px 0 #9fff0e, 1px 1px 0 #9fff0e;
  & > div {
    & > p {
      font-family: 'Montserrat', sans-serif;
      color: ${({ theme }) => theme.text1};
      text-shadow: none;
      font-weight: 400;
      font-size: 21px;
      line-height: 26px;
    }
  }
  @media (max-width: 575px) {
    font-size: 40px;
  }
`
