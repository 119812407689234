import { Actions, SaleStatus } from 'constants/crowdsale'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import { useMinting } from 'hooks/useMinting'
import { useSaleInformation } from 'hooks/useSaleInformation'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components/macro'
import { weiToEther } from 'utils/balance'

import MintImg from '../../../assets/navlink/Mint.png'
import MintMore from '../../../assets/navlink/MintMore.png'
import SoldOut from '../../../assets/navlink/SoldOut.png'
import HeroVideoMp4 from '../../../assets/video/heroVideo.mp4'
import { useBalance } from '../../../hooks/useBalance'
import { useWhitelist } from '../../../hooks/useWhitelist'
import {
  useCloseModal,
  useErrorModalToggle,
  useLoadingModalToggle,
  useModalOpen,
  useSuccessModalToggle,
} from '../../../state/application/hooks'
import { ApplicationModal } from '../../../state/application/reducer'
import AwaitingDrop from '../../AwaitingDrop'
import IconPortal from '../../IconPortal'
import ModalError from '../../Modal/ModaError'
import ModalLoading from '../../Modal/ModalLoading'
import ModalSuccess from '../../Modal/ModalSuccess'
import Web3Status from '../../Web3Status'

const Hero = () => {
  const { balance, numberMinted, updateBalance, updateNumberMinted } = useBalance()
  const closeModal = useCloseModal()
  const { saleInformation, updateSaleInformation } = useSaleInformation()
  const {
    mint,
    canMint,
    setMintingAmount,
    decreaseMintingAmount,
    increaseMintingAmount,
    buyableAmount,
    lastMintingResult,
    mintingAmount,
    mintMore,
  } = useMinting()
  const { isWhitelisted } = useWhitelist()
  const [action, setAction] = useState<Actions | undefined>(Actions.CONNECT)
  const { account } = useActiveWeb3React()

  const [disable, setDisable] = useState<boolean>(false)
  const [inputError, setInputError] = useState<boolean>(false)
  const [mintConfirm, setMintConfirm] = useState<boolean>(false)

  const handleChangeQuantity = (e: any) => {
    const value = e.currentTarget.value.replace(/\D/g, '')
    if (value >= 1) {
      setInputError(value > buyableAmount)
      setMintingAmount(parseInt(value.replace(/\D/g, '')))
    } else if (value === '') {
      setMintingAmount(0)
      setInputError(true)
    } else {
      setInputError(true)
    }
  }
  const toggleLoadingModal = useLoadingModalToggle()
  const loadingModalOpen = useModalOpen(ApplicationModal.POPUP_LOADING)
  const toggleSuccessModal = useSuccessModalToggle()
  const successModalOpen = useModalOpen(ApplicationModal.POPUP_SUCCESS)
  const toggleErrorModal = useErrorModalToggle()
  const errorModalOpen = useModalOpen(ApplicationModal.POPUP_ERROR)

  const isEmpty = (obj: any) => {
    return Object.keys(obj).length === 0
  }

  useEffect(() => {
    if (lastMintingResult.status === 0) {
      closeModal()
    }
    if (lastMintingResult.status === 1) {
    }
    if (lastMintingResult.status === 2) {
      setDisable(true)
      toggleLoadingModal()
    }
    if (lastMintingResult.status === 3) {
      toggleErrorModal()
      setDisable(false)
    }
    if (lastMintingResult.status === 4) {
      setMintConfirm(true)
      toggleSuccessModal()
      setDisable(false)
      updateSaleInformation()
      updateNumberMinted()
      setTimeout(() => {
        closeModal()
      }, 2000)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastMintingResult])

  return (
    <HeroWrapper>
      <HeroMain>
        <DivRow>
          <DivImageProduct>
            <DivImageWrapper>
              <DivImageBox>
                <DivSliderWrapper>
                  <DivSlideImage>
                    <video id="video-1" muted preload="true" autoPlay loop style={{ width: '100%' }}>
                      <source src={HeroVideoMp4} type="video/mp4" />
                      <source src={HeroVideoMp4} type="video/webm" />
                      <p>Your user agent does not support the HTML5 Video element.</p>
                    </video>
                  </DivSlideImage>
                </DivSliderWrapper>
              </DivImageBox>
            </DivImageWrapper>
          </DivImageProduct>
          <DivInfoProduct>
            {isEmpty(saleInformation) ? (
              <DivInfoProductContent style={{ alignItems: 'center' }}>
                <ValueRemaining>Loading...</ValueRemaining>
              </DivInfoProductContent>
            ) : (
              <DivInfoProductContent>
                {saleInformation.saleStatus === SaleStatus.NOT_STARTED && <AwaitingDrop />}
                {(saleInformation.saleStatus === SaleStatus.PRE_SALE ||
                  saleInformation.saleStatus === SaleStatus.SALE) && (
                  <>
                    <MintingSectionHeader>
                      {lastMintingResult.status === 4
                        ? `YOU HAVE MINTED ${lastMintingResult.lastMinted} ${
                            lastMintingResult.lastMinted <= 1 ? 'NFT' : 'NFTs'
                          }`
                        : (saleInformation.saleStatus === SaleStatus.PRE_SALE ? 'PRE-SALE ' : 'SALE ') + 'IN PROGRESS'}
                    </MintingSectionHeader>
                    {saleInformation.maxSupply && (
                      <>
                        <ValueRemaining>
                          The {saleInformation.saleStatus === SaleStatus.PRE_SALE ? 'private sale' : 'public sale '} of
                          Drip Lab NFTs is currently active.
                          <br />
                        </ValueRemaining>
                        <ValueRemaining style={{ display: 'block' }}>
                          <strong>{saleInformation.totalMinted} </strong> of{' '}
                          <strong>
                            {' '}
                            {saleInformation.saleStatus === SaleStatus.PRE_SALE
                              ? saleInformation.maxSupply_Presale + ' (presale NFTs) '
                              : saleInformation.maxSupply}
                          </strong>{' '}
                          have been minted so far.
                          <br />
                        </ValueRemaining>
                      </>
                    )}
                    <DivPrice>
                      {!account ? (
                        <>
                          <Price color={'#ADFF08'}>
                            <br />
                            Connect to participate of the presale and get a piece from the walls.
                          </Price>
                          <br />
                          <br />
                          <ConnectButton>
                            <Web3Status />
                          </ConnectButton>
                        </>
                      ) : saleInformation.saleStatus === SaleStatus.PRE_SALE && !isWhitelisted ? (
                        <Price color={'red'}>
                          <br />
                          You are not whitelisted! Make sure you are connected with the right wallet account.
                        </Price>
                      ) : (
                        parseFloat(weiToEther(balance, 3)) <
                          parseFloat(
                            weiToEther(
                              saleInformation.saleStatus === SaleStatus.PRE_SALE
                                ? saleInformation.preSalePrice
                                : saleInformation.salePrice,
                              3
                            )
                          ) ?? (
                          <Price color={'red'}>
                            <br />
                            You don&#39;t have enough balance to mint. The price per NFT is{' '}
                            {weiToEther(
                              saleInformation.saleStatus === SaleStatus.PRE_SALE
                                ? saleInformation.preSalePrice
                                : saleInformation.salePrice,
                              2
                            )}{' '}
                            . Please top up your wallet account to mint.
                          </Price>
                        )
                      )}
                    </DivPrice>
                    {account && buyableAmount > 0 && (
                      <div>
                        <DivPrice>
                          <Price>
                            The max minting amount is {saleInformation.maxPresale}
                            {saleInformation.maxPresale !== buyableAmount ? (
                              <>
                                , but you can mint up to{' '}
                                <strong style={{ color: '#ADFF08' }}>{buyableAmount} Drip Lab NFTs</strong> with your
                                current balance
                              </>
                            ) : (
                              ''
                            )}
                          </Price>
                          <Price style={{ display: 'block' }}>
                            The price is{' '}
                            <strong style={{ color: '#ADFF08' }}>
                              {weiToEther(
                                saleInformation.saleStatus === SaleStatus.PRE_SALE
                                  ? saleInformation.preSalePrice
                                  : saleInformation.salePrice,
                                3
                              )}{' '}
                              ETH
                            </strong>{' '}
                            for every piece.
                          </Price>
                        </DivPrice>
                        {mintConfirm ? (
                          <ButtonMintMore
                            className={'grow'}
                            onClick={() => {
                              setMintConfirm(false)
                              mintMore()
                              updateBalance()
                            }}
                            type={'button'}
                          >
                            <IconPortal SrcImageIcon={MintMore} widthIcon={'100%'} heightIcon={'100%'} />
                          </ButtonMintMore>
                        ) : (
                          <>
                            <DivQuantity>
                              <InputWrapper>
                                <ArrowBtnLeft inputError={inputError} onClick={decreaseMintingAmount}>
                                  {'<'}
                                </ArrowBtnLeft>
                                <InputNumberControl
                                  type={'number'}
                                  onChange={handleChangeQuantity}
                                  min="1"
                                  max={buyableAmount}
                                  value={mintingAmount}
                                  inputError={inputError}
                                />
                                <ArrowBtnRight inputError={inputError} onClick={increaseMintingAmount}>
                                  {'>'}
                                </ArrowBtnRight>
                              </InputWrapper>
                            </DivQuantity>
                            <ButtonBuy
                              className={canMint() ? 'grow' : ''}
                              onClick={() => mint()}
                              type={'button'}
                              disabled={!canMint() || inputError || disable}
                            >
                              <IconPortal SrcImageIcon={MintImg} widthIcon={'100%'} heightIcon={'100%'} />
                            </ButtonBuy>
                          </>
                        )}
                      </div>
                    )}
                    <DivPrice>
                      {account && numberMinted && numberMinted?.toNumber() > 0 && isWhitelisted && (
                        <>
                          <br />
                          <Price color={'#ADFF08'}>
                            {numberMinted?.toNumber() === saleInformation.maxPresale ? (
                              <>You minted the max of {saleInformation.maxPresale} NFTs allowed on the presale.</>
                            ) : (
                              <>
                                You already minted <strong>{numberMinted?.toNumber()}</strong> NFTs.
                              </>
                            )}
                          </Price>
                        </>
                      )}
                    </DivPrice>
                  </>
                )}
                {saleInformation.saleStatus === SaleStatus.PRE_SALE_ENDED && (
                  <>
                    <AwaitingDrop />
                    <DivPrice style={{ textAlign: 'center' }}>
                      {saleInformation.totalMinted} NFTs / {saleInformation.maxSupply} have been minted so far
                    </DivPrice>
                  </>
                )}
                {saleInformation.saleStatus === SaleStatus.ENDED && (
                  <IconPortal SrcImageIcon={SoldOut} widthIcon={'100%'} heightIcon={'100%'} />
                )}
                {(saleInformation.saleStatus === SaleStatus.PRE_SALE_PAUSED ||
                  saleInformation.saleStatus === SaleStatus.SALE_PAUSED) && (
                  <MintingSectionHeader style={{ textAlign: 'center' }}>
                    {saleInformation.saleStatus === SaleStatus.PRE_SALE_PAUSED ? 'PRE-SALE ' : 'SALE '} IS PAUSED
                  </MintingSectionHeader>
                )}
              </DivInfoProductContent>
            )}

            <ModalLoading
              maxWidth={620}
              isOpen={loadingModalOpen}
              onDismiss={toggleLoadingModal}
              data={{
                type: 'mint',
                message: `Your ${lastMintingResult.lastMinted <= 1 ? 'NFT is' : 'NFTs are'} being minted...`,
              }}
            />
            <ModalSuccess isOpen={successModalOpen} onDismiss={toggleSuccessModal} />
            <ModalError isOpen={errorModalOpen} onDimiss={toggleErrorModal} />
          </DivInfoProduct>
        </DivRow>
      </HeroMain>
    </HeroWrapper>
  )
}

export default Hero
// const ModalTextAnimated = styled.p`
//   font-family: 'Montserrat', sans-serif;
//   color: ${({ theme }) => theme.text1};
//   text-align: center;
//   font-style: normal;
//   font-weight: 600;
//   font-size: 20px;
//   line-height: 29px;
//   @keyframes pulse {
//     0% {
//       transform: scale(0.9);
//       color: transparent;
//     }
//     100% {
//       transform: scale(1.03);
//       color: black;
//     }
//   }
//   animation: pulse 1.5s infinite;
// `

const ConnectButton = styled.div`
  width: 200px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 100%;
  `};
`
const MintingSectionHeader = styled.h1`
  font-size: 2rem;
`

const InputWrapper = styled.div`
  height: 50px;
  display: flex;
  align-items: center;
`
const ArrowBtnLeft = styled.button<{ inputError?: boolean }>`
  cursor: pointer;
  border: transparent;
  background: ${({ inputError, theme }) => (!inputError ? theme.green2 : theme.red1)};
  color: ${({ theme }) => theme.black};
  padding: 0 10px !important;
  font-size: 2rem;
  height: 100%;
  border-radius: 15px 0 0 15px;
  &:hover {
    color: green;
  }
`
const ArrowBtnRight = styled.button<{ inputError?: boolean }>`
  cursor: pointer;
  border: transparent;
  background: ${({ inputError, theme }) => (!inputError ? theme.green2 : theme.red1)};
  color: ${({ theme }) => theme.black};
  padding: 0 10px !important;
  font-size: 2rem;
  height: 100%;
  border-radius: 0 15px 15px 0;
  &:hover {
    color: green;
  }
`
const HeroWrapper = styled.div`
  padding-top: 64px;
  padding-bottom: 116px;
  width: 100%;
  ${({ theme }) => theme.mediaWidth.upToSmall`
   padding-top: 28px;
   padding-bottom: 60px;
  `};
`
const HeroMain = styled.div`
  padding-left: 16px;
  padding-right: 16px;
`
const DivRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`
const DivImageProduct = styled.div`
  flex: 0 0 41.66667%;
  max-width: 41.66667%;
  padding-left: 16px;
  padding-right: 16px;
  display: flex;
  align-items: center;
  ${({ theme }) => theme.mediaWidth.upToMedium`
      flex: 0 0 50%;
      max-width: 50%;
  `};
  @media (max-width: 575px) {
    flex: 0 0 100%;
    max-width: 100%;
    display: none;
  }
`

const DivInfoProduct = styled.div`
  flex: 0 0 50%;
  max-width: 50%;
  padding-left: 16px;
  padding-right: 16px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  ${({ theme }) => theme.mediaWidth.upToMedium`
      flex: 0 0 50%;
      max-width: 50%;
  `};
  @media (max-width: 575px) {
    flex: 0 0 100%;
    max-width: 100%;
    margin-top: 28px;
  }
`
const DivPrice = styled.div`
  margin-bottom: 32px;
  ${({ theme }) => theme.mediaWidth.upToLarge`
    margin-bottom: 16px;
  `};
  @media (max-width: 575px) {
    order: 2;
    margin-bottom: 0;
    display: flex;
    flex-direction: column;
  }
`
const Price = styled.p<{ color?: string }>`
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 1.4419;
  color: ${({ color, theme }) => (color ? color : theme.text1)};
  letter-spacing: 0.9px;
  margin: 0;
  @media (max-width: 575px) {
    font-size: 16px;
    flex: 1;
    display: flex;
    align-items: center;
  }
`
const DivQuantity = styled.div`
  margin-bottom: 32px;
  ${({ theme }) => theme.mediaWidth.upToLarge`
    margin-bottom: 16px;
  `};
  @media (max-width: 575px) {
    order: 1;
    margin-bottom: 0;
  }
`
const InputNumberControl = styled.input<{ inputError: boolean }>`
  max-width: 122px;
  width: 100%;
  height: 100%;
  text-align: center;
  border: 1px solid ${({ inputError, theme }) => (!inputError ? theme.green2 : theme.red1)};

  outline: none;
  padding: 11px 20px;
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 1.4419;
  color: ${({ theme }) => theme.text1};
  letter-spacing: 0.9px;
  background-color: transparent;

  -moz-appearance: textfield;
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  @media (max-width: 575px) {
    max-width: 78px;
    text-align: center;
    font-size: 16px;
    padding: 8px;
  }
`
const ValueRemaining = styled.p`
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 1.4419;
  color: ${({ theme }) => theme.text1};
  letter-spacing: 0.9px;
  margin: 0;
  @media (max-width: 575px) {
    font-size: 16px;
    flex: 1;
    display: flex;
    align-items: center;
  }
`
const DivInfoProductContent = styled.div`
  // margin-left: 40px;
  display: flex;
  flex-direction: column;
  ${({ theme }) => theme.mediaWidth.upToLarge`
    margin-left: 32px;
  `};
  ${({ theme }) => theme.mediaWidth.upToMedium`
      margin-left: 16px;
  `};
  @media (max-width: 575px) {
    margin-left: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
`

const DivImageWrapper = styled.div`
  max-width: 554px;
  width: 100%;
  // border: 1px solid ${({ theme }) => theme.green2};
`
const DivImageBox = styled.div`
  width: 100%;
  height: 0;
  padding-bottom: 100%;
  position: relative;
`
const DivSliderWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`
const DivSlideImage = styled.div`
  & > img {
    width: 100%;
    display: block;
    object-fit: cover;
    vertical-align: middle;
    min-height: 1px;
  }
`
const ButtonBuy = styled.button`
  cursor: pointer;
  max-width: 150px;
  outline: none;
  border: none;
  background-color: ${({ theme }) => theme.green2};
  padding: 12px 43px;
  border-radius: 4px;

  &:disabled {
    background-color: ${({ theme }) => theme.textGray};
    cursor: not-allowed;
  }
  ${({ theme }) => theme.mediaWidth.upToLarge`
    // margin-left: 25px;
    margin-top: 16px;
  `};
  ${({ theme }) => theme.mediaWidth.upToMedium`
      margin-left: 16px;
  `};
  @media (max-width: 575px) {
    margin-left: 0;
    // max-width: none;
    font-size: 18px;
  }
`

const ButtonMintMore = styled.button`
  cursor: pointer;
  max-width: 170px;
  outline: none;
  border: none;
  background-color: ${({ theme }) => theme.green2};
  padding: 13px 33px;
  border-radius: 4px;

  &:disabled {
    background-color: ${({ theme }) => theme.textGray};
    cursor: not-allowed;
  }
  ${({ theme }) => theme.mediaWidth.upToLarge`
    // margin-left: 25px;
    margin-top: 16px;
  `};
  ${({ theme }) => theme.mediaWidth.upToMedium`
      margin-left: 16px;
  `};
  @media (max-width: 575px) {
    margin-left: 0;
    // max-width: none;
    font-size: 18px;
  }
`
