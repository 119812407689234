export enum SaleStatus {
  NOT_STARTED,
  PRE_SALE,
  PRE_SALE_PAUSED,
  PRE_SALE_ENDED,
  SALE,
  SALE_PAUSED,
  ENDED,
}

export enum Actions {
  CONNECT,
  PRE_MINT,
  MINT,
  WAIT,
  BUY_RESALES,
  TOP_UP,
}

export enum MintingStatus {
  NO_MINTING,
  PRE_MINTING,
  MINTING,
  ERROR,
  FINISHED,
}
