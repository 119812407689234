import './_faq.scss'

import React from 'react'
import Slider from 'react-slick'
import styled from 'styled-components/macro'

import ImageBgFAQDs from '../../../assets/images/image_bg_faq_ds.png'
import ImageBgFAQMb from '../../../assets/images/image_bg_faq_mb.png'
import ImageIndex_1 from '../../../assets/images/image_index_1.png'
import ImageIndex_2 from '../../../assets/images/image_index_2.png'
import ImageIndex_3 from '../../../assets/images/image_index_3.png'
import ImageIndex_4 from '../../../assets/images/image_index_4.png'
// import IconPortal from '../../IconPortal'
import data from './data.json'

const imageArray = [ImageIndex_1, ImageIndex_2, ImageIndex_3, ImageIndex_4]
let { dataSlider } = data
dataSlider = dataSlider.map((item, index) => {
  return {
    ...item,
    image: imageArray[index],
  }
})

const SectionFAQ = () => {
  const settings = {
    dots: false,
    slidesToShow: 4,
    slidesToScroll: 4,
    swipe: false,
    draggable: false,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          dots: true,
          swipe: true,
          draggable: true,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true,
          swipe: true,
          draggable: true,
        },
      },
    ],
  }
  return (
    <SectionWrapper>
      <Main>
        <Header>
          <DivImageHeader>
            <picture>
              <source media="(max-width: 767px)" srcSet={ImageBgFAQMb} />
              <source media="(min-width: 768px)" srcSet={ImageBgFAQDs} />
              <img src={ImageBgFAQDs} alt="Join Discord" />
            </picture>
          </DivImageHeader>
        </Header>
        <ListFaq>
          <Slider {...settings}>
            {dataSlider.map((slider, index) => {
              return (
                <DivItem key={index}>
                  <DivIconIndex>
                    <img
                      src={slider.image}
                      alt={'Another'}
                      className={'shrink'}
                      style={{ width: '56px', height: '56px' }}
                    />
                    {/* <IconPortal className={'grow'} SrcImageIcon={slider.image} widthIcon={'56px'} heightIcon={'56px'} /> */}
                  </DivIconIndex>
                  <DivItemContent>
                    <ItemHeading>{slider.question}</ItemHeading>
                    <ItemAnswer>{slider.answer}</ItemAnswer>
                  </DivItemContent>
                </DivItem>
              )
            })}
          </Slider>
        </ListFaq>
      </Main>
    </SectionWrapper>
  )
}
const SectionWrapper = styled.div`
  width: 100%;
  padding-top: 32px;
  padding-bottom: 40px;
  border-width: 1px 0 1px 0;
  border-style: solid;
  border-color: ${({ theme }) => theme.green2};
`
const Main = styled.div``
const Header = styled.div``
const DivImageHeader = styled.div`
  margin-bottom: 32px;
  picture {
    display: block;
  }
  img {
    display: block;
    width: 100%;
    vertical-align: middle;
    object-fit: cover;
    min-height: 1px;
  }
  @media (max-width: 767px) {
    img {
      height: 70px;
      object-fit: contain;
    }
    padding-left: 16px;
    margin-bottom: 20px;
  }
`
const ListFaq = styled.div`
  .slick-list {
    overflow: hidden;
  }
  .slick-slide {
    padding: 0 15px;
  }
  .slick-slide:first-of-type {
    padding-left: 0;
  }
  .slick-slide:last-of-type {
    padding-right: 0;
  }
  .slick-dots {
    display: flex !important;
    justify-content: center;
    list-style: none;
    padding-left: 0;
    li {
      display: block;
      width: 16px;
      height: 16px;
      background-color: ${({ theme }) => theme.white};
      border-radius: 50%;
      margin-left: 10px;
    }
    li.slick-active {
      background-color: ${({ theme }) => theme.green2};
    }
    button {
      width: 16px;
      height: 16px;
      border-radius: 50%;
      text-indent: -9999px;
      opacity: 0;
    }
  }
  @media (max-width: 1023px) {
    .slick-slide:first-of-type {
      padding-left: 15px;
    }
    .slick-slide:last-of-type {
      padding-right: 15px;
    }
    .slick-dots {
      margin-top: 32px;
      margin-bottom: 0;
    }
  }
`
const DivItem = styled.div``
const DivIconIndex = styled.div`
  margin-bottom: 24px;
  overflow: visible;
  @media (max-width: 575px) {
    display: none;
  }
`
const DivItemContent = styled.div``
const ItemHeading = styled.h4`
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 1.2;
  letter-spacing: -0.9px;
  color: ${({ theme }) => theme.text1};
  margin: 0 0 32px 0;
`
const ItemAnswer = styled.p`
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 1.25;
  color: ${({ theme }) => theme.text1};
`
export default SectionFAQ
