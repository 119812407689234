import { whitelistAddresses } from 'constants/whitelistedAddresses'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import keccak256 from 'keccak256'
import { MerkleTree } from 'merkletreejs'
import { useEffect, useState } from 'react'

const leafNodes = whitelistAddresses.map((address) => keccak256(address))
const merkleTree = new MerkleTree(leafNodes, keccak256, { sortPairs: true })

console.log(merkleTree.toString())

const rootHash = merkleTree.getRoot()

export function useWhitelist() {
  const { account, chainId } = useActiveWeb3React()
  const [isWhitelisted, setIsWhitelisted] = useState<boolean | undefined>(undefined)
  const [hexProof, setHexProof] = useState<string[] | undefined>(undefined)

  const checkWhitelistedStatus = (address: string | null | undefined): boolean | undefined => {
    if (address) {
      const newLeaf = keccak256(address)
      const hexProof = merkleTree.getHexProof(newLeaf)
      setHexProof(hexProof)
      return merkleTree.verify(hexProof, newLeaf, rootHash)
    } else {
      return undefined
    }
  }

  useEffect(() => {
    setIsWhitelisted(checkWhitelistedStatus(account))
  }, [chainId, account])

  return {
    checkWhitelistedStatus,
    isWhitelisted,
    hexProof,
  }
}
