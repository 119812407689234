import Hero from '../../components/mint/Hero'
import SectionDescription from '../../components/mint/SectionDescription'
import SectionFAQ from '../../components/mint/SectionFAQ'

export default function Mint() {
  return (
    <>
      <Hero />
      <SectionDescription />
      <SectionFAQ />
    </>
  )
}
