import { ChainIds } from './chains'

const INFURA_KEY = process.env.REACT_APP_INFURA_KEY
if (typeof INFURA_KEY === 'undefined') {
  throw new Error(`REACT_APP_INFURA_KEY must be a defined environment variable`)
}

/**
 * These are the network URLs used by the interface when there is not another available source of chain data
 */
export const INFURA_NETWORK_URLS: { [key in ChainIds]: string } = {
  [ChainIds.MAINNET]: `https://mainnet.infura.io/v3/${INFURA_KEY}`,
  [ChainIds.RINKEBY]: `https://rinkeby.infura.io/v3/${INFURA_KEY}`,
  [ChainIds.ROPSTEN]: `https://ropsten.infura.io/v3/${INFURA_KEY}`,
  [ChainIds.GOERLI]: `https://goerli.infura.io/v3/${INFURA_KEY}`,
  [ChainIds.KOVAN]: `https://kovan.infura.io/v3/${INFURA_KEY}`,
  [ChainIds.POLYGON]: `https://polygon-mainnet.infura.io/v3/${INFURA_KEY}`,
  [ChainIds.POLYGON_MUMBAI]: `https://polygon-mumbai.infura.io/v3/${INFURA_KEY}`,
}
