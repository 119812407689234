import { BigNumber } from '@ethersproject/bignumber'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import JSBI from 'jsbi'
import { useEffect, useState } from 'react'

import { useCrowdsaleContract } from './useCrowdsaleContract'
import useIsWindowVisible from './useIsWindowVisible'

export function useBalance() {
  const { active, account, chainId, library } = useActiveWeb3React()
  const crowdsaleContract = useCrowdsaleContract()
  const windowVisible = useIsWindowVisible()
  const [balance, setBalance] = useState<JSBI>()
  const [numberMinted, setNumberMinted] = useState<BigNumber>()

  const updateBalance = () => {
    if (account && library && chainId && windowVisible) {
      library
        .getBalance(account)
        .then((result) => {
          setBalance(JSBI.BigInt(result))
        })
        .catch((error: any) => {
          console.error(`Failed to get balance`, error)
        })
    } else {
      setBalance(undefined)
    }
  }

  const updateNumberMinted = () => {
    if (account && library && chainId && windowVisible) {
      crowdsaleContract
        ?.numberMinted(account)
        .then((result) => {
          setNumberMinted(result)
        })
        .catch((error: any) => {
          console.error(`Failed to get balance`, error)
        })
    } else {
      setBalance(undefined)
    }
  }

  useEffect(() => {
    updateBalance()
    updateNumberMinted()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [active, chainId, library, account, windowVisible])

  return { balance, updateBalance, numberMinted, updateNumberMinted }
}
