// eslint-disable-next-line no-restricted-imports
import { darken } from 'polished'
import { Activity } from 'react-feather'
import styled, { css } from 'styled-components/macro'
import { UnsupportedChainIdError, useWeb3React } from 'web3-react-core'

import ConnectImg from '../../assets/navlink/Connect.png'
import { NetworkContextName } from '../../constants/misc'
import { useWalletModalToggle } from '../../state/application/hooks'
import { shortenAddress } from '../../utils'
import { ButtonSecondary } from '../Button'
import IconPortal from '../IconPortal'
import WalletModal from '../WalletModal'

const Web3StatusGeneric = styled(ButtonSecondary)`
  ${({ theme }) => theme.flexRowNoWrap}
  width: 100%;
  align-items: center;
  padding: 1rem;
  cursor: pointer;
  user-select: none;
  height: 40px;
  :focus {
    outline: none;
  }
`
const Web3StatusError = styled(Web3StatusGeneric)`
  background-color: ${({ theme }) => theme.red1};
  border: 1px solid ${({ theme }) => theme.red1};
  color: ${({ theme }) => theme.white};
  font-weight: 500;
  :hover,
  :focus {
    background-color: ${({ theme }) => darken(0.1, theme.red1)};
  }
`

const Web3StatusConnect = styled(Web3StatusGeneric)<{ faded?: boolean }>`
  background-color: ${({ theme }) => theme.primary3};
  border: none;

  color: ${({ theme }) => theme.black};
  font-weight: 500;

  ${({ faded }) =>
    faded &&
    css`
      background-color: ${({ theme }) => theme.primary1};
      color: ${({ theme }) => theme.black};

      :hover,
      :focus {
        background-color: ${({ theme }) => theme.primary1};
      }
    `}
`

const Web3StatusConnected = styled(Web3StatusGeneric)<{ pending?: boolean }>`
  background-color: ${({ pending, theme }) => (pending ? theme.primary1 : theme.bg1)};
  border: none;
  border-left: 1px solid rgba(173, 255, 8, 0.5);
  color: rgba(173, 255, 8, 1);
  font-family: 'Montserrat', serif;
  font-weight: 500;
  :hover,
  :focus {
    background-color: rgba(173, 255, 8, 1);
    color: black;
    :focus {
      background-color: rgba(173, 255, 8, 1);
      color: black;
    }
  }
`

const Text = styled.p`
  flex: 1 1 auto;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin: 0 0.5rem 0 0.25rem;
  font-size: 1rem;
  width: fit-content;
  font-weight: 500;
`

const NetworkIcon = styled(Activity)`
  margin-left: 0.25rem;
  margin-right: 0.5rem;
  width: 16px;
  height: 16px;
`

function Web3StatusInner() {
  const { account, error } = useWeb3React()

  const toggleWalletModal = useWalletModalToggle()

  if (account) {
    return (
      <Web3StatusConnected id="web3-status-connected" onClick={toggleWalletModal}>
        <Text>{shortenAddress(account)}</Text>
      </Web3StatusConnected>
    )
  } else if (error) {
    return (
      <Web3StatusError onClick={toggleWalletModal}>
        <NetworkIcon />
        <Text>{error instanceof UnsupportedChainIdError ? 'Wrong Network' : 'Error'}</Text>
      </Web3StatusError>
    )
  } else {
    return (
      <Web3StatusConnect className={'grow'} id="connect-wallet" onClick={toggleWalletModal} faded={!account}>
        <IconPortal SrcImageIcon={ConnectImg} widthIcon={'auto'} heightIcon={'20px'} />
      </Web3StatusConnect>
    )
  }
}

export default function Web3Status() {
  const { active } = useWeb3React()
  const contextNetwork = useWeb3React(NetworkContextName)

  return (
    <>
      <Web3StatusInner />
      {(contextNetwork.active || active) && <WalletModal />}
    </>
  )
}
