import { JsonRpcProvider } from '@ethersproject/providers'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import { CrowdsaleERC7211A } from 'lib/CrowdsaleERC7211A'
import crowdsaleERC7211AABI from 'lib/crowdsaleERC7211A.json'
import { useMemo } from 'react'
import { getContract, getContractWithoutSigner } from 'utils'

const CrowdsaleTokenAddress = process.env.REACT_APP_CROWDSALE_TOKEN_ADDRESS || ''

export function useCrowdsaleContract(withSignerIfPossible = true): null | CrowdsaleERC7211A {
  const { library, account, chainId } = useActiveWeb3React()

  return useMemo(() => {
    if (!library || !chainId) {
      const provider = new JsonRpcProvider(process.env.REACT_APP_RPC_URL)
      try {
        return getContractWithoutSigner(CrowdsaleTokenAddress, crowdsaleERC7211AABI, provider) as CrowdsaleERC7211A
      } catch (e) {
        return null
      }
    }
    try {
      return getContract(
        CrowdsaleTokenAddress,
        crowdsaleERC7211AABI,
        library,
        withSignerIfPossible && account ? account : undefined
      ) as CrowdsaleERC7211A
    } catch (e) {
      return null
    }
  }, [chainId, library, withSignerIfPossible, account])
}
